export default [
    {
        "key": "11",
        "label": "北京市",
        "children": [
            {
                "key": "1100",
                "label": "北京市"
            }
        ]
    },
    {
        "key": "12",
        "label": "天津市",
        "children": [
            {
                "key": "1200",
                "label": "天津市"
            }
        ]
    },
    {
        "key": "13",
        "label": "河北省",
        "children": [
            {
                "key": "1301",
                "label": "石家庄市"
            },
            {
                "key": "1302",
                "label": "唐山市"
            },
            {
                "key": "1303",
                "label": "秦皇岛市"
            },
            {
                "key": "1304",
                "label": "邯郸市"
            },
            {
                "key": "1305",
                "label": "邢台市"
            },
            {
                "key": "1306",
                "label": "保定市"
            },
            {
                "key": "1307",
                "label": "张家口市"
            },
            {
                "key": "1308",
                "label": "承德市"
            },
            {
                "key": "1309",
                "label": "沧州市"
            },
            {
                "key": "1310",
                "label": "廊坊市"
            },
            {
                "key": "1311",
                "label": "衡水市\t"
            }
        ]
    },
    {
        "key": "14",
        "label": "山西省",
        "children": [
            {
                "key": "1401",
                "label": "太原市"
            },
            {
                "key": "1402",
                "label": "大同市"
            },
            {
                "key": "1403",
                "label": "阳泉市"
            },
            {
                "key": "1404",
                "label": "长治市"
            },
            {
                "key": "1405",
                "label": "晋城市"
            },
            {
                "key": "1406",
                "label": "朔州市"
            },
            {
                "key": "1407",
                "label": "晋中市"
            },
            {
                "key": "1408",
                "label": "运城市"
            },
            {
                "key": "1409",
                "label": "忻州市"
            },
            {
                "key": "1410",
                "label": "临汾市"
            },
            {
                "key": "1411",
                "label": "吕梁市\t"
            }
        ]
    },
    {
        "key": "15",
        "label": "内蒙古自治区",
        "children": [
            {
                "key": "1501",
                "label": "呼和浩特市"
            },
            {
                "key": "1502",
                "label": "包头市"
            },
            {
                "key": "1503",
                "label": "乌海市"
            },
            {
                "key": "1504",
                "label": "赤峰市"
            },
            {
                "key": "1505",
                "label": "通辽市"
            },
            {
                "key": "1506",
                "label": "鄂尔多斯市"
            },
            {
                "key": "1507",
                "label": "呼伦贝尔市"
            },
            {
                "key": "1508",
                "label": "巴彦淖尔市"
            },
            {
                "key": "1509",
                "label": "乌兰察布市"
            },
            {
                "key": "1522",
                "label": "兴安盟"
            },
            {
                "key": "1525",
                "label": "锡林郭勒盟"
            },
            {
                "key": "1529",
                "label": "阿拉善盟\t"
            }
        ]
    },
    {
        "key": "21",
        "label": "辽宁省",
        "children": [
            {
                "key": "2101",
                "label": "沈阳市"
            },
            {
                "key": "2102",
                "label": "大连市"
            },
            {
                "key": "2103",
                "label": "鞍山市"
            },
            {
                "key": "2104",
                "label": "抚顺市"
            },
            {
                "key": "2105",
                "label": "本溪市"
            },
            {
                "key": "2106",
                "label": "丹东市"
            },
            {
                "key": "2107",
                "label": "锦州市"
            },
            {
                "key": "2108",
                "label": "营口市"
            },
            {
                "key": "2109",
                "label": "阜新市"
            },
            {
                "key": "2110",
                "label": "辽阳市"
            },
            {
                "key": "2111",
                "label": "盘锦市"
            },
            {
                "key": "2112",
                "label": "铁岭市"
            },
            {
                "key": "2113",
                "label": "朝阳市"
            },
            {
                "key": "2114",
                "label": "葫芦岛市\t"
            }
        ]
    },
    {
        "key": "22",
        "label": "吉林省",
        "children": [
            {
                "key": "2201",
                "label": "长春市"
            },
            {
                "key": "2202",
                "label": "吉林市"
            },
            {
                "key": "2203",
                "label": "四平市"
            },
            {
                "key": "2204",
                "label": "辽源市"
            },
            {
                "key": "2205",
                "label": "通化市"
            },
            {
                "key": "2206",
                "label": "白山市"
            },
            {
                "key": "2207",
                "label": "松原市"
            },
            {
                "key": "2208",
                "label": "白城市"
            },
            {
                "key": "2224",
                "label": "延边朝鲜族自治州"
            }
        ]
    },
    {
        "key": "23",
        "label": "黑龙江省",
        "children": [
            {
                "key": "2301",
                "label": "哈尔滨市"
            },
            {
                "key": "2302",
                "label": "齐齐哈尔市"
            },
            {
                "key": "2303",
                "label": "鸡西市"
            },
            {
                "key": "2304",
                "label": "鹤岗市"
            },
            {
                "key": "2305",
                "label": "双鸭山市"
            },
            {
                "key": "2306",
                "label": "大庆市"
            },
            {
                "key": "2307",
                "label": "伊春市"
            },
            {
                "key": "2308",
                "label": "佳木斯市"
            },
            {
                "key": "2309",
                "label": "七台河市"
            },
            {
                "key": "2310",
                "label": "牡丹江市"
            },
            {
                "key": "2311",
                "label": "黑河市"
            },
            {
                "key": "2312",
                "label": "绥化市"
            },
            {
                "key": "2327",
                "label": "大兴安岭地区"
            }
        ]
    },
    {
        "key": "31",
        "label": "上海市",
        "children": [
            {
                "key": "3100",
                "label": "上海市\t"
            }
        ]
    },
    {
        "key": "32",
        "label": "江苏省",
        "children": [
            {
                "key": "3201",
                "label": "南京市"
            },
            {
                "key": "3202",
                "label": "无锡市"
            },
            {
                "key": "3203",
                "label": "徐州市"
            },
            {
                "key": "3204",
                "label": "常州市"
            },
            {
                "key": "3205",
                "label": "苏州市"
            },
            {
                "key": "3206",
                "label": "南通市"
            },
            {
                "key": "3207",
                "label": "连云港市"
            },
            {
                "key": "3208",
                "label": "淮安市"
            },
            {
                "key": "3209",
                "label": "盐城市"
            },
            {
                "key": "3210",
                "label": "扬州市"
            },
            {
                "key": "3211",
                "label": "镇江市"
            },
            {
                "key": "3212",
                "label": "泰州市"
            },
            {
                "key": "3213",
                "label": "宿迁市"
            }
        ]
    },
    {
        "key": "33",
        "label": "浙江省",
        "children": [
            {
                "key": "3301",
                "label": "杭州市"
            },
            {
                "key": "3302",
                "label": "宁波市"
            },
            {
                "key": "3303",
                "label": "温州市"
            },
            {
                "key": "3304",
                "label": "嘉兴市"
            },
            {
                "key": "3305",
                "label": "湖州市"
            },
            {
                "key": "3306",
                "label": "绍兴市"
            },
            {
                "key": "3307",
                "label": "金华市"
            },
            {
                "key": "3308",
                "label": "衢州市"
            },
            {
                "key": "3309",
                "label": "舟山市"
            },
            {
                "key": "3310",
                "label": "台州市"
            },
            {
                "key": "3311",
                "label": "丽水市"
            }
        ]
    },
    {
        "key": "34",
        "label": "安徽省",
        "children": [
            {
                "key": "3401",
                "label": "合肥市"
            },
            {
                "key": "3402",
                "label": "芜湖市"
            },
            {
                "key": "3403",
                "label": "蚌埠市"
            },
            {
                "key": "3404",
                "label": "淮南市"
            },
            {
                "key": "3405",
                "label": "马鞍山市"
            },
            {
                "key": "3406",
                "label": "淮北市"
            },
            {
                "key": "3407",
                "label": "铜陵市"
            },
            {
                "key": "3408",
                "label": "安庆市"
            },
            {
                "key": "3410",
                "label": "黄山市"
            },
            {
                "key": "3411",
                "label": "滁州市"
            },
            {
                "key": "3412",
                "label": "阜阳市"
            },
            {
                "key": "3413",
                "label": "宿州市"
            },
            {
                "key": "3414",
                "label": "巢湖市"
            },
            {
                "key": "3415",
                "label": "六安市"
            },
            {
                "key": "3416",
                "label": "亳州市"
            },
            {
                "key": "3417",
                "label": "池州市"
            },
            {
                "key": "3418",
                "label": "宣城市\t"
            }
        ]
    },
    {
        "key": "35",
        "label": "福建省",
        "children": [
            {
                "key": "3501",
                "label": "福州市"
            },
            {
                "key": "3502",
                "label": "厦门市"
            },
            {
                "key": "3503",
                "label": "莆田市"
            },
            {
                "key": "3504",
                "label": "三明市"
            },
            {
                "key": "3505",
                "label": "泉州市"
            },
            {
                "key": "3506",
                "label": "漳州市"
            },
            {
                "key": "3507",
                "label": "南平市"
            },
            {
                "key": "3508",
                "label": "龙岩市"
            },
            {
                "key": "3509",
                "label": "宁德市\t"
            }
        ]
    },
    {
        "key": "36",
        "label": "江西省",
        "children": [
            {
                "key": "3601",
                "label": "南昌市"
            },
            {
                "key": "3602",
                "label": "景德镇市"
            },
            {
                "key": "3603",
                "label": "萍乡市"
            },
            {
                "key": "3604",
                "label": "九江市"
            },
            {
                "key": "3605",
                "label": "新余市"
            },
            {
                "key": "3606",
                "label": "鹰潭市"
            },
            {
                "key": "3607",
                "label": "赣州市"
            },
            {
                "key": "3608",
                "label": "吉安市"
            },
            {
                "key": "3609",
                "label": "宜春市"
            },
            {
                "key": "3610",
                "label": "抚州市"
            },
            {
                "key": "3611",
                "label": "上饶市\t"
            }
        ]
    },
    {
        "key": "37",
        "label": "山东省",
        "children": [
            {
                "key": "3701",
                "label": "济南市"
            },
            {
                "key": "3702",
                "label": "青岛市"
            },
            {
                "key": "3703",
                "label": "淄博市"
            },
            {
                "key": "3704",
                "label": "枣庄市"
            },
            {
                "key": "3705",
                "label": "东营市"
            },
            {
                "key": "3706",
                "label": "烟台市"
            },
            {
                "key": "3707",
                "label": "潍坊市"
            },
            {
                "key": "3708",
                "label": "济宁市"
            },
            {
                "key": "3709",
                "label": "泰安市"
            },
            {
                "key": "3710",
                "label": "威海市"
            },
            {
                "key": "3711",
                "label": "日照市"
            },
            {
                "key": "3712",
                "label": "莱芜市"
            },
            {
                "key": "3713",
                "label": "临沂市"
            },
            {
                "key": "3714",
                "label": "德州市"
            },
            {
                "key": "3715",
                "label": "聊城市"
            },
            {
                "key": "3716",
                "label": "滨州市"
            },
            {
                "key": "3717",
                "label": "菏泽市"
            }
        ]
    },
    {
        "key": "41",
        "label": "河南省",
        "children": [
            {
                "key": "4101",
                "label": "郑州市"
            },
            {
                "key": "4102",
                "label": "开封市"
            },
            {
                "key": "4103",
                "label": "洛阳市"
            },
            {
                "key": "4104",
                "label": "平顶山市"
            },
            {
                "key": "4105",
                "label": "安阳市"
            },
            {
                "key": "4106",
                "label": "鹤壁市"
            },
            {
                "key": "4107",
                "label": "新乡市"
            },
            {
                "key": "4108",
                "label": "焦作市"
            },
            {
                "key": "4109",
                "label": "濮阳市"
            },
            {
                "key": "4110",
                "label": "许昌市"
            },
            {
                "key": "4111",
                "label": "漯河市"
            },
            {
                "key": "4112",
                "label": "三门峡市"
            },
            {
                "key": "4113",
                "label": "南阳市"
            },
            {
                "key": "4114",
                "label": "商丘市"
            },
            {
                "key": "4115",
                "label": "信阳市"
            },
            {
                "key": "4116",
                "label": "周口市"
            },
            {
                "key": "4117",
                "label": "驻马店市\t"
            }
        ]
    },
    {
        "key": "42",
        "label": "湖北省",
        "children": [
            {
                "key": "4201",
                "label": "武汉市"
            },
            {
                "key": "4202",
                "label": "黄石市"
            },
            {
                "key": "4203",
                "label": "十堰市"
            },
            {
                "key": "4205",
                "label": "宜昌市"
            },
            {
                "key": "4206",
                "label": "襄樊市"
            },
            {
                "key": "4207",
                "label": "鄂州市"
            },
            {
                "key": "4208",
                "label": "荆门市"
            },
            {
                "key": "4209",
                "label": "孝感市"
            },
            {
                "key": "4210",
                "label": "荆州市"
            },
            {
                "key": "4211",
                "label": "黄冈市"
            },
            {
                "key": "4212",
                "label": "咸宁市"
            },
            {
                "key": "4213",
                "label": "随州市"
            },
            {
                "key": "4228",
                "label": "恩施土家族苗族自治州"
            },
            {
                "key": "4290",
                "label": "省直辖行政单位\t"
            }
        ]
    },
    {
        "key": "43",
        "label": "湖南省",
        "children": [
            {
                "key": "4301",
                "label": "长沙市"
            },
            {
                "key": "4302",
                "label": "株洲市"
            },
            {
                "key": "4303",
                "label": "湘潭市"
            },
            {
                "key": "4304",
                "label": "衡阳市"
            },
            {
                "key": "4305",
                "label": "邵阳市"
            },
            {
                "key": "4306",
                "label": "岳阳市"
            },
            {
                "key": "4307",
                "label": "常德市"
            },
            {
                "key": "4308",
                "label": "张家界市"
            },
            {
                "key": "4309",
                "label": "益阳市"
            },
            {
                "key": "4310",
                "label": "郴州市"
            },
            {
                "key": "4311",
                "label": "永州市"
            },
            {
                "key": "4312",
                "label": "怀化市"
            },
            {
                "key": "4313",
                "label": "娄底市"
            },
            {
                "key": "4331",
                "label": "湘西土家族苗族自治州\t"
            }
        ]
    },
    {
        "key": "44",
        "label": "广东省",
        "children": [
            {
                "key": "4401",
                "label": "广州市"
            },
            {
                "key": "4402",
                "label": "韶关市"
            },
            {
                "key": "4403",
                "label": "深圳市"
            },
            {
                "key": "4404",
                "label": "珠海市"
            },
            {
                "key": "4405",
                "label": "汕头市"
            },
            {
                "key": "4406",
                "label": "佛山市"
            },
            {
                "key": "4407",
                "label": "江门市"
            },
            {
                "key": "4408",
                "label": "湛江市"
            },
            {
                "key": "4409",
                "label": "茂名市"
            },
            {
                "key": "4412",
                "label": "肇庆市"
            },
            {
                "key": "4413",
                "label": "惠州市"
            },
            {
                "key": "4414",
                "label": "梅州市"
            },
            {
                "key": "4415",
                "label": "汕尾市"
            },
            {
                "key": "4416",
                "label": "河源市"
            },
            {
                "key": "4417",
                "label": "阳江市"
            },
            {
                "key": "4418",
                "label": "清远市"
            },
            {
                "key": "4419",
                "label": "东莞市"
            },
            {
                "key": "4420",
                "label": "中山市"
            },
            {
                "key": "4451",
                "label": "潮州市"
            },
            {
                "key": "4452",
                "label": "揭阳市"
            },
            {
                "key": "4453",
                "label": "云浮市\t"
            }
        ]
    },
    {
        "key": "45",
        "label": "广西壮族自治区",
        "children": [
            {
                "key": "4501",
                "label": "南宁市"
            },
            {
                "key": "4502",
                "label": "柳州市"
            },
            {
                "key": "4503",
                "label": "桂林市"
            },
            {
                "key": "4504",
                "label": "梧州市"
            },
            {
                "key": "4505",
                "label": "北海市"
            },
            {
                "key": "4506",
                "label": "防城港市"
            },
            {
                "key": "4507",
                "label": "钦州市"
            },
            {
                "key": "4508",
                "label": "贵港市"
            },
            {
                "key": "4509",
                "label": "玉林市"
            },
            {
                "key": "4510",
                "label": "百色市"
            },
            {
                "key": "4511",
                "label": "贺州市"
            },
            {
                "key": "4512",
                "label": "河池市"
            },
            {
                "key": "4513",
                "label": "来宾市"
            },
            {
                "key": "4514",
                "label": "崇左市\t"
            }
        ]
    },
    {
        "key": "46",
        "label": "海南省",
        "children": [
            {
                "key": "4601",
                "label": "海口市"
            },
            {
                "key": "4602",
                "label": "三亚市"
            },
            {
                "key": "4690",
                "label": "直辖县级行政单位"
            }
        ]
    },
    {
        "key": "50",
        "label": "重庆市",
        "children": [
            {
                "key": "5000",
                "label": "重庆市\t"
            }
        ]
    },
    {
        "key": "51",
        "label": "四川省",
        "children": [
            {
                "key": "5101",
                "label": "成都市"
            },
            {
                "key": "5103",
                "label": "自贡市"
            },
            {
                "key": "5104",
                "label": "攀枝花市"
            },
            {
                "key": "5105",
                "label": "泸州市"
            },
            {
                "key": "5106",
                "label": "德阳市"
            },
            {
                "key": "5107",
                "label": "绵阳市"
            },
            {
                "key": "5108",
                "label": "广元市"
            },
            {
                "key": "5109",
                "label": "遂宁市"
            },
            {
                "key": "5110",
                "label": "内江市"
            },
            {
                "key": "5111",
                "label": "乐山市"
            },
            {
                "key": "5113",
                "label": "南充市"
            },
            {
                "key": "5114",
                "label": "眉山市"
            },
            {
                "key": "5115",
                "label": "宜宾市"
            },
            {
                "key": "5116",
                "label": "广安市"
            },
            {
                "key": "5117",
                "label": "达州市"
            },
            {
                "key": "5118",
                "label": "雅安市"
            },
            {
                "key": "5119",
                "label": "巴中市"
            },
            {
                "key": "5120",
                "label": "资阳市"
            },
            {
                "key": "5132",
                "label": "阿坝藏族羌族自治州"
            },
            {
                "key": "5133",
                "label": "甘孜藏族自治州"
            },
            {
                "key": "5134",
                "label": "凉山彝族自治州"
            }
        ]
    },
    {
        "key": "52",
        "label": "贵州省",
        "children": [
            {
                "key": "5201",
                "label": "贵阳市"
            },
            {
                "key": "5202",
                "label": "六盘水市"
            },
            {
                "key": "5203",
                "label": "遵义市"
            },
            {
                "key": "5204",
                "label": "安顺市"
            },
            {
                "key": "5222",
                "label": "铜仁地区"
            },
            {
                "key": "5223",
                "label": "黔西南布依族苗族自治州"
            },
            {
                "key": "5224",
                "label": "毕节地区"
            },
            {
                "key": "5226",
                "label": "黔东南苗族侗族自治州"
            },
            {
                "key": "5227",
                "label": "黔南布依族苗族自治州\t"
            }
        ]
    },
    {
        "key": "53",
        "label": "云南省",
        "children": [
            {
                "key": "5301",
                "label": "昆明市"
            },
            {
                "key": "5303",
                "label": "曲靖市"
            },
            {
                "key": "5304",
                "label": "玉溪市"
            },
            {
                "key": "5305",
                "label": "保山市"
            },
            {
                "key": "5306",
                "label": "昭通市"
            },
            {
                "key": "5307",
                "label": "丽江市"
            },
            {
                "key": "5308",
                "label": "思茅市"
            },
            {
                "key": "5309",
                "label": "临沧市"
            },
            {
                "key": "5323",
                "label": "楚雄彝族自治州"
            },
            {
                "key": "5325",
                "label": "红河哈尼族彝族自治州"
            },
            {
                "key": "5326",
                "label": "文山壮族苗族自治州"
            },
            {
                "key": "5328",
                "label": "西双版纳傣族自治州"
            },
            {
                "key": "5329",
                "label": "大理白族自治州"
            },
            {
                "key": "5331",
                "label": "德宏傣族景颇族自治州"
            },
            {
                "key": "5333",
                "label": "怒江傈僳族自治州"
            },
            {
                "key": "5334",
                "label": "迪庆藏族自治州"
            }
        ]
    },
    {
        "key": "54",
        "label": "西藏自治区",
        "children": [
            {
                "key": "5401",
                "label": "拉萨市"
            },
            {
                "key": "5421",
                "label": "昌都地区"
            },
            {
                "key": "5422",
                "label": "山南地区"
            },
            {
                "key": "5423",
                "label": "日喀则地区"
            },
            {
                "key": "5424",
                "label": "那曲地区"
            },
            {
                "key": "5425",
                "label": "阿里地区"
            },
            {
                "key": "5426",
                "label": "林芝地区\t"
            }
        ]
    },
    {
        "key": "61",
        "label": "陕西省",
        "children": [
            {
                "key": "6101",
                "label": "西安市"
            },
            {
                "key": "6102",
                "label": "铜川市"
            },
            {
                "key": "6103",
                "label": "宝鸡市"
            },
            {
                "key": "6104",
                "label": "咸阳市"
            },
            {
                "key": "6105",
                "label": "渭南市"
            },
            {
                "key": "6106",
                "label": "延安市"
            },
            {
                "key": "6107",
                "label": "汉中市"
            },
            {
                "key": "6108",
                "label": "榆林市"
            },
            {
                "key": "6109",
                "label": "安康市"
            },
            {
                "key": "6110",
                "label": "商洛市\t"
            }
        ]
    },
    {
        "key": "62",
        "label": "甘肃省",
        "children": [
            {
                "key": "6201",
                "label": "兰州市"
            },
            {
                "key": "6202",
                "label": "嘉峪关市"
            },
            {
                "key": "6203",
                "label": "金昌市"
            },
            {
                "key": "6204",
                "label": "白银市"
            },
            {
                "key": "6205",
                "label": "天水市"
            },
            {
                "key": "6206",
                "label": "武威市"
            },
            {
                "key": "6207",
                "label": "张掖市"
            },
            {
                "key": "6208",
                "label": "平凉市"
            },
            {
                "key": "6209",
                "label": "酒泉市"
            },
            {
                "key": "6210",
                "label": "庆阳市"
            },
            {
                "key": "6211",
                "label": "定西市"
            },
            {
                "key": "6212",
                "label": "陇南市"
            },
            {
                "key": "6229",
                "label": "临夏回族自治州"
            },
            {
                "key": "6230",
                "label": "甘南藏族自治州"
            }
        ]
    },
    {
        "key": "63",
        "label": "青海省",
        "children": [
            {
                "key": "6301",
                "label": "西宁市"
            },
            {
                "key": "6321",
                "label": "海东地区"
            },
            {
                "key": "6322",
                "label": "海北藏族自治州"
            },
            {
                "key": "6323",
                "label": "黄南藏族自治州"
            },
            {
                "key": "6325",
                "label": "海南藏族自治州"
            },
            {
                "key": "6326",
                "label": "果洛藏族自治州"
            },
            {
                "key": "6327",
                "label": "玉树藏族自治州"
            },
            {
                "key": "6328",
                "label": "海西蒙古族藏族自治州\t"
            }
        ]
    },
    {
        "key": "64",
        "label": "宁夏回族自治区",
        "children": [
            {
                "key": "6401",
                "label": "银川市"
            },
            {
                "key": "6402",
                "label": "石嘴山市"
            },
            {
                "key": "6403",
                "label": "吴忠市"
            },
            {
                "key": "6404",
                "label": "固原市"
            },
            {
                "key": "6405",
                "label": "中卫市\t"
            }
        ]
    },
    {
        "key": "65",
        "label": "新疆维吾尔自治区",
        "children": [
            {
                "key": "6501",
                "label": "乌鲁木齐市"
            },
            {
                "key": "6502",
                "label": "克拉玛依市"
            },
            {
                "key": "6521",
                "label": "吐鲁番地区"
            },
            {
                "key": "6522",
                "label": "哈密地区"
            },
            {
                "key": "6523",
                "label": "昌吉回族自治州"
            },
            {
                "key": "6527",
                "label": "博尔塔拉蒙古自治州"
            },
            {
                "key": "6528",
                "label": "巴音郭楞蒙古自治州"
            },
            {
                "key": "6529",
                "label": "阿克苏地区"
            },
            {
                "key": "6530",
                "label": "克孜勒苏柯尔克孜自治州"
            },
            {
                "key": "6531",
                "label": "喀什地区"
            },
            {
                "key": "6532",
                "label": "和田地区"
            },
            {
                "key": "6540",
                "label": "伊犁哈萨克自治州"
            },
            {
                "key": "6542",
                "label": "塔城地区"
            },
            {
                "key": "6543",
                "label": "阿勒泰地区"
            },
            {
                "key": "6590",
                "label": "省直辖行政单位"
            }
        ]
    },
    {
        "key": "71",
        "label": "台湾省",
        "children": [
            {
                "key": "7100",
                "label": "台湾省"
            }
        ]
    },
    {
        "key": "81",
        "label": "香港特别行政区",
        "children": [
            {
                "key": "8100",
                "label": "香港特别行政区"
            }
        ]
    },
    {
        "key": "82",
        "label": "澳门特别行政区",
        "children": [
            {
                "key": "8200",
                "label": "澳门特别行政区"
            }
        ]
    }
]